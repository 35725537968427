import { render, staticRenderFns } from "./DoTpc.vue?vue&type=template&id=80ccbd9e&scoped=true&"
import script from "./DoTpc.vue?vue&type=script&lang=js&"
export * from "./DoTpc.vue?vue&type=script&lang=js&"
import style0 from "./DoTpc.vue?vue&type=style&index=0&id=80ccbd9e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ccbd9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VApp,VBtn,VCard,VCardTitle,VChip,VCol,VContainer,VDivider,VIcon,VImg,VMain,VRadio,VRadioGroup,VRow,VSheet,VSlideGroup,VSlideItem,VSlideYTransition,VTextField})
